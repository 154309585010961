import { IconType } from '@/external/icon';
import { generateEmailShareLink } from '@/utils/generateShareLink';

type ShareLinkConfig = {
  icon: IconType;
  shareLinkGenerator: (params: { linkTitle: string; link: string }) => string;
};
export const SHARE_CHANNELS = [
  'FACEBOOK',
  'TWITTER',
  'LINKEDIN',
  'EMAIL',
] as const;
export type ShareChannel = (typeof SHARE_CHANNELS)[number];
export const SHARE_LINK_CONFIG_PER_CHANNEL: Record<
  ShareChannel,
  ShareLinkConfig
> = {
  FACEBOOK: {
    icon: 'facebook',
    shareLinkGenerator({ link }) {
      const shareLink = new URL('https://www.facebook.com/sharer.php');
      shareLink.searchParams.append('u', link);
      return shareLink.toString();
    },
  },
  TWITTER: {
    icon: 'twitter',
    shareLinkGenerator({ link, linkTitle }) {
      const shareLink = new URL('https://twitter.com/intent/tweet');
      shareLink.searchParams.append('text', linkTitle);
      shareLink.searchParams.append('url', link);
      return shareLink.toString();
    },
  },
  LINKEDIN: {
    icon: 'linkedin',
    shareLinkGenerator({ link, linkTitle }) {
      const shareLink = new URL('https://www.linkedin.com/shareArticle');
      shareLink.searchParams.append('title', linkTitle);
      shareLink.searchParams.append('url', link);
      return shareLink.toString();
    },
  },
  EMAIL: {
    icon: 'mailEnvelope',
    shareLinkGenerator: generateEmailShareLink,
  },
};
