'use client';
import Icon from '@/external/icon';
import { ShareChannel, SHARE_LINK_CONFIG_PER_CHANNEL } from './constants';

export const ShareLink = ({
  channel,
  linkTitle,
  url,
}: {
  channel: ShareChannel;
  linkTitle: string;
  url: string;
}) => {
  const { icon, shareLinkGenerator } = SHARE_LINK_CONFIG_PER_CHANNEL[channel];

  const shareLink = shareLinkGenerator({ linkTitle, link: url });

  return (
    <a href={shareLink}>
      <div className="bg-primarySpaceGrey w-10 tablet-sm:w-14 p-2.5 flex justify-center items-center aspect-square rounded-full hover:bg-primarySpaceGrey/90">
        <Icon icon={icon} className="w-8 aspect-square fill-white" />
      </div>
    </a>
  );
};
