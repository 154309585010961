import Icon from '@/external/icon';
import { cn } from '@/lib/tailwind';
import { CtaData } from '@/types';

type Props = Pick<CtaData, 'icon' | 'label' | 'className'>;

const TextLink = ({ icon, label, className }: Props) => {
  return (
    <span
      className={cn(
        'flex items-center gap-3 text-baseBlack dark:text-white hover:text-primaryOceanBlue hover:underline',
        className,
      )}
    >
      <Icon
        icon={icon?.name ?? 'arrow'}
        className="fill-primaryOceanBlue h-3.5 w-3.5 desktop-lg:h-[18px] desktop-lg:w-[18px] rtl:-scale-100"
      />
      <span
        className={cn(
          'whitespace-nowrap',
          `text-center uppercase text-mobile-sm/label-1 tablet-sm:text-tablet-sm/overline-4 desktop-sm:text-tablet-sm/overline-3 desktop-lg:text-desktop-large/overline-3`,
        )}
      >
        {label}
      </span>
    </span>
  );
};

export default TextLink;
